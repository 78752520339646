<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.myHeader {
  display: none;
  &-height {
    height: 100px;
  }
  &-con {
    width: 100vw;
    height: 100px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background: @color_theme;
    color: #fff;
  }
  &-left {
    flex: 1;
    i {
      width: 80px;
      height: 80px;
      font-size: 40px;
      font-weight: bold;
    }
  }
  &-right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 3%;
  }
  &-title {
    flex: 2;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
  }
}
</style>
<template>
  <div class="myHeader">
    <div class="myHeader-con flex-center-between">
      <div class="myHeader-left">
        <van-icon
          v-if="showBack"
          class="flex-center"
          name="arrow-left"
          @click="$router.go(-1)"
        />
        <slot name="slot-left" />
      </div>
      <div class="myHeader-title">{{ title }}</div>
      <div class="myHeader-right">
        <slot name="slot-right" />
      </div>
    </div>
    <!-- 占位用的 -->
    <div class="myHeader-height"></div>
  </div>
</template>

<script>
export default {
  name: 'myHeader',
  props: {
    title: {
      type: String,
      default: '标题'
    },
    showBack: {
      type: Boolean,
      default: true
    }
  }
}
</script>
