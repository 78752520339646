// eslint-disable-next-line
import Vue from 'vue'
import 'vant/lib/index.css'
import './reset.less'
import {
  Popup,
  Cell,
  CellGroup,
  ActionSheet,
  NumberKeyboard,
  PasswordInput,
  Tab,
  Tabs,
  Loading,
  Swipe,
  SwipeItem,
  // Pagination,
  // Dialog,
  // Autocomplete,
  // Dropdown,
  // DropdownMenu,
  // DropdownItem,
  // Menu,
  // Submenu,
  // MenuItem,
  // MenuItemGroup,
  // Input,
  // InputNumber,
  // Radio,
  // RadioGroup,
  // RadioButton,
  Checkbox,
  // CheckboxButton,
  // CheckboxGroup,
  Switch,
  // Select,
  // Option,
  // OptionGroup,
  Button,
  // ButtonGroup,
  // Table,
  // TableColumn,
  Picker,
  // DatePicker,
  // TimeSelect,
  // TimePicker,
  // Popover,
  // Tooltip,
  // Breadcrumb,
  // BreadcrumbItem,
  Form,
  Field,
  // Tabs,
  // TabPane,
  // Tag,
  // Tree,
  // Alert,
  // Slider,
  Icon,
  Row,
  Col,
  Uploader,
  // Progress,
  // Spinner,
  // Badge,
  // Card,
  // Rate,
  // Steps,
  // Step,
  // Carousel,
  // CarouselItem,
  Collapse,
  CollapseItem,
  // Cascader,
  // ColorPicker,
  // Transfer,
  // Container,
  // Header,
  // Aside,
  // Main,
  // Footer,
  // Timeline,
  // TimelineItem,
  // Link,
  // Divider,
  Image,
  Calendar,
  // Backtop,
  // PageHeader,
  // CascaderPanel,
  // Scrollbar
  // xxx
} from 'vant' // UI框架，为减小项目打包体积，已配置按需引入，请务必遵守！
Vue.use(Popup)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(ActionSheet)
Vue.use(NumberKeyboard)
Vue.use(PasswordInput)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Loading)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(SwipeItem)
// Vue.use(Pagination)
// Vue.use(Dialog)
// Vue.use(Autocomplete)
// Vue.use(Dropdown)
// Vue.use(DropdownMenu)
// Vue.use(DropdownItem)
// Vue.use(Menu)
// Vue.use(Submenu)
// Vue.use(MenuItem)
// Vue.use(MenuItemGroup)
// Vue.use(Input)
// Vue.use(InputNumber)
// Vue.use(Radio)
// Vue.use(RadioGroup)
// Vue.use(RadioButton)
Vue.use(Checkbox)
// Vue.use(CheckboxButton)
// Vue.use(CheckboxGroup)
Vue.use(Switch)
// Vue.use(Select)
// Vue.use(Option)
// Vue.use(OptionGroup)
Vue.use(Button)
// Vue.use(ButtonGroup)
// Vue.use(Table)
// Vue.use(TableColumn)
Vue.use(Picker)
// Vue.use(DatePicker)
// Vue.use(TimeSelect)
// Vue.use(TimePicker)
// Vue.use(Popover)
// Vue.use(Tooltip)
// Vue.use(Breadcrumb)
// Vue.use(BreadcrumbItem)
Vue.use(Form)
Vue.use(Field)
// Vue.use(Tabs)
// Vue.use(TabPane)
// Vue.use(Tag)
// Vue.use(Tree)
// Vue.use(Alert)
// Vue.use(Slider)
Vue.use(Icon)
Vue.use(Row)
Vue.use(Col)
Vue.use(Uploader)
// Vue.use(Progress)
// Vue.use(Spinner)
// Vue.use(Badge)
// Vue.use(Card)
// Vue.use(Rate)
// Vue.use(Steps)
// Vue.use(Step)
// Vue.use(Carousel)
// Vue.use(CarouselItem)
Vue.use(Collapse)
Vue.use(CollapseItem)
// Vue.use(Cascader)
// Vue.use(ColorPicker)
// Vue.use(Transfer)
// Vue.use(Container)
// Vue.use(Header)
// Vue.use(Aside)
// Vue.use(Main)
// Vue.use(Footer)
// Vue.use(Timeline)
// Vue.use(TimelineItem)
// Vue.use(Link)
// Vue.use(Divider)
Vue.use(Image)
Vue.use(Calendar)
// Vue.use(Backtop)
// Vue.use(PageHeader)
// Vue.use(CascaderPanel)
// Vue.use(Scrollbar)
// Vue.use(xxx)
