<style lang="less">
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}
</style>
<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
// @ is an alias to /src
export default {
  components: {},
  data() {
    return {}
  },
  created() {
    // this.corpMediumId = '360200059095504'
    // this.corpMediumId = '1655486052939591680'
    // this.$store.commit('SET_CORP_MEDIUM_ID', this.corpMediumId)
    /* eslint-disable */
    console.log('PROCESSENV', PROCESSENV)
    console.log('Navigator', navigator)
    let UserAgent = navigator.userAgent.toLowerCase()
    console.log('userAgent', UserAgent)
    let flag = UserAgent.match(/(phone|pad|pod|iphone|ipod|ios|ipad|android|mobile|blackberry|iemobile|mqqbrowser|juc|fennec|wosbrowser|browserng|webos|symbian|windows phone)/i)
    console.log('flag', flag)
    let browserInfo = {}
    let browserArray = {
      IE: window.ActiveXObject || "ActiveXObject" in window,
      // IE
      Chrome: UserAgent.indexOf('chrome') > -1 && UserAgent.indexOf('safari') > -1,
      // Chrome浏览器
      Firefox: UserAgent.indexOf('firefox') > -1,
      // 火狐浏览器
      Opera: UserAgent.indexOf('opera') > -1,
      // Opera浏览器
      Safari: UserAgent.indexOf('safari') > -1 && UserAgent.indexOf('chrome') == -1,
      // safari浏览器
      Edge: UserAgent.indexOf('edge') > -1,
      // Edge浏览器
      QQBrowser: /qqbrowser/.test(UserAgent),
      // qq浏览器
      WeixinBrowser: /MicroMessenger/i.test(UserAgent)
      // 微信浏览器
    }

    let systemVersion = ''
    let systemMessage = UserAgent.substring(UserAgent.indexOf('(')+1, UserAgent.indexOf(')'))
    console.log('systemMessage', systemMessage)
    let systemMessageArr = systemMessage.split(' ')
    console.log('systemMessageArr', systemMessageArr)
    if (UserAgent.indexOf('windows') > -1) {
      systemVersion = 'windows ' + systemMessageArr[systemMessageArr.indexOf('windows')+2].replace(';', '')
    }
    if (UserAgent.indexOf('android') > -1) {
      systemVersion = 'android ' + systemMessageArr[systemMessageArr.indexOf('android')+1].replace(';', '')
    }
    if (UserAgent.indexOf('macintosh') > -1) {

    }
    if (UserAgent.indexOf('os') > -1) {
      systemVersion = 'os ' + systemMessageArr[systemMessageArr.indexOf('os')+1].replace(';', '')
    }
    console.log('systemVersion', systemVersion)

    for (let i in browserArray) {
      if (browserArray[i]) {
        let versions = ''
        if (i === 'IE') {
          versions = UserAgent.match(/(msie\s|trident.*rv:)([\w.]+)/)[2]
        } else if (i === 'Chrome') {
          for (let mt in navigator.mimeTypes) {
            //检测是否是360浏览器(测试只有pc端的360才起作用)
            if (navigator.mimeTypes[mt]['type'] == 'application/360softmgrplugin') {
              i = '360'
            }
          }
          versions = UserAgent.match(/chrome\/([\d.]+)/)[1]
        } else if (i === 'Firefox') {
          versions = UserAgent.match(/firefox\/([\d.]+)/)[1]
        } else if (i == 'Opera') {
          versions = UserAgent.match(/opera\/([\d.]+)/)[1]
        } else if (i === 'Safari') {
          if (UserAgent.indexOf('version') > -1) {
            versions = UserAgent.match(/version\/([\d.]+)/)[1]
          } else {
            console.log(UserAgent.match(/safari\/([\d.]+)/))
            versions = UserAgent.match(/safari\/([\d.]+)/)[1]
          }
        } else if (i === 'Edge') {
          versions = UserAgent.match(/edge\/([\d.]+)/)[1]
        } else if (i === 'QQBrowser') {
          versions = UserAgent.match(/qqbrowser\/([\d.]+)/)[1]
        }
        browserInfo.type = i
        browserInfo.versions = parseInt(versions)
      }
    }
    console.log('browserArray', browserArray)
    console.log('browserInfo', browserInfo)
  },
  beforeDestroy() {
    sessionStorage.clear()
  }
}
</script>
