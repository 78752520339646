import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'home'
  },
  {
    path: '/home',
    name: 'home',
    meta: { title: '申请' },
    component: () => import('@/views/home')
  },
  {
    path: '/result',
    name: 'result',
    meta: { title: '额度' },
    component: () => import('@/views/result')
  }
]

const router = new VueRouter({
  routes
})

const defaultTitle = ''
router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title : defaultTitle
  next()
})

export default router
