const user = {
  state: {
    token: sessionStorage.getItem('token')
  },
  actions: {},
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
      sessionStorage.setItem('token', token)
    }
  }
}
export default user
