import './assets/styles/reset.less'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/_index'
import MyHeader from './components/myHeader'
import { Toast } from 'vant'
import { signFigures } from './utils/signFigures'
import { decimalsFormatch } from './utils/decimalsFormatch'
import { DatetimePicker } from 'vant'
// import VConsole from 'vconsole'
// new VConsole()

Vue.use(DatetimePicker)

Vue.prototype.$message = (option) => {
  return Toast(option)
}

Vue.prototype.$message.success = (msg) => {
  return Toast.success(msg)
}

Vue.prototype.$message.error = (msg) => {
  return Toast.fail(msg)
}

Vue.prototype.$jumpTo = (path, query) => {
  if (!path) {
    Toast.fail('该模块还没开通，敬请期待！')
    return
  }
  router.push({
    path: path,
    query: query
  })
}
// 精度处理
Vue.prototype.$signFigures = (num, rank) => {
  console.log('num', num)
  return signFigures(num, rank)
}
// 精度处理 + 小数位（2位）格式化，返回string
Vue.prototype.$decimalsFormatch = (num) => {
  return decimalsFormatch(num)
}
Vue.config.productionTip = false
Vue.component('MyHeader', MyHeader)
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
