import { signFigures } from './signFigures'

export const decimalsFormatch = (num) => {
  // 先去掉小数和处理精度问题，并转为字符串格式
  let numString = signFigures(num * 100) + ''
  // 用字符串截取的方式
  let numString1 = numString.substring(0, numString.length - 2) || '0'
  let numString2 = numString.substring(numString.length - 2, numString.length) || '0'
  let numString3 = numString2.length < 2 ? ('0' + numString2) : numString2
  return numString1 + '.' + numString3
}